.homepage-social-icons {
  margin: var(--margin-unit-sm) 0;

  @media (--md-min) {
    margin: var(--margin-unit-lg) 0;
  }
}

.homepage-social-icons__hit {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .social-media:hover {
    & + .homepage-social-icons__hover {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
}

.homepage-social-icons__hover {
  position: relative;
  top: 10px;
  transform: translate3d(0, 10%, 0);
  opacity: 0;
  transition: all 350ms;
  backface-visibility: hidden;
  pointer-events: none;
}