.site-footer {
  background-color: #000;
  color: #fff;
  padding: var(--margin-outer-sm) var(--margin-outer-sm);

  @media (--md-min) {
    padding: var(--margin-outer-lg) var(--margin-outer-lg) calc(var(--margin-outer-lg) + 5px);
  }
}

.site-footer__inner {
  display: flex;

  @media (--md-max) {
    flex-wrap: wrap;
  }

  @media (--md-min) {
    justify-content: space-between;
    align-items: center;
  }
}

.site-footer__menu {
  @media (--md-min) {
    display: flex;

    a {
      margin-top: 2px;
    }
  }

  a {
    border-bottom: 1px solid;

    @media (--md-min) {
      border-bottom-width: 2px;
    }
  }

  a + a {
    margin-left: 0.5em;

    @media (--md-min) {
      margin-left: 1em;
    }
  }
}

.site-footer__left {
  @media (--md-min) {
    display: flex;
    align-items: center;
  }
}

.site-footer__right {
  @media (--md-max) {
    margin-top: 15px;
  }
}

.site-footer__menu--left {
  @media (--md-max) {
    order: 1;
    flex: 1 0 auto;
  }
}

.site-footer__menu--right {
  @media (--md-max) {
    order: 2;
  }
}

.site-footer__social {
  @media (--md-min) {
    margin-left: 3em;
    flex: 1 0 auto;
  }

  @media (--md-max) {
    width: 100%;
    margin: var(--margin-section-top-sm) 0;
    order: 3;
  }

  .social-media {
    justify-content: flex-start;
  }

  svg {
    fill: #fff;
  }
}

.site-footer__social-label {
  margin-block: 10px;

  @media (--md-min) {
    display: none;
  }
}