.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media__icon {
  display: block;
  
  & + .social-media__icon {
    margin-left: 15px;
  }

  svg {
    display: block;
    width: auto;
    height: 18px;
  }


  @media (--md-min) {
    & + .social-media__icon {
      margin-left: 25px;
    }

    svg {
      height: 25px;
    }
  }
}

.social-media__icon--round {
  svg {
    height: 21px;
  }

  @media (--md-min) {
    svg {
      height: 27px;
    }
  }
}