*, *:before, *:after {
  box-sizing: border-box;
}

body {
  background-color: #fff;
}

.wrap {
  min-height: calc(100vh - 60px - var(--ios-gap, 0.01px));
  display: flex;
  flex-direction: column;
}

.site {
  flex: 1 0 auto;
  overflow-x: hidden;
  overflow-y: hidden;
}

.skip-to-content-link {
  background: #fff;
  border: 2px solid #000;
  left: 0;
  top: 0;
  padding: 8px;
  position: absolute;
  z-index: 101;
  transform: translateY(-100%);
  transition: transform 0.3s;
  font-weight: 700;
  border-radius: 0;
  visibility: hidden;
}

.skip-to-content-link:focus {
  transform: translateY(0%);
  left: 5px;
  top: 5px;
  visibility: visible;
}

.limit-width {
  max-width: var(--limit-width);
  margin-right: auto;
  margin-left: auto;
}