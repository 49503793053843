.speech-bubble {
  position: relative;
}

.speech-bubble__inner {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
  border-radius: 15px;

  @media (--md-min) {
    padding: 15px;
    border-radius: 15px;

    .t-size-medium {
      margin: 0 10px;
    }
  }

  a {
    display: inline-block;
    border-bottom: 1px solid;

    @media (--md-min) {
      border-width: 2px;
    }
  }
}

.speech-bubble__point {
  position: relative;
  flex: 0 0 auto;
}

.speech-bubble--top {
  padding-top: 25px;

  .speech-bubble__point {
    height: 25px;
    width: 56px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);

    svg {
      display: block;
      height: 100%;
    }
  }
}

.speech-bubble--left,
.speech-bubble--right {
  display: flex;

  .speech-bubble__point {
    height: 28px;
    width: 25px;
    
    svg {
      display: block;
      width: 100%;
    }
  }
}

.speech-bubble--left {
  padding-left: 25px;

  .speech-bubble__inner {
    border-top-left-radius: 0;
  }

  .speech-bubble__point {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.speech-bubble--right {
  padding-right: 24.7px;

  .speech-bubble__point {
    position: absolute;
    top: 0;
    right: 0;
  }

  .speech-bubble__inner {
    border-top-right-radius: 0;
  }
}

.speech-bubble--top-right {
  padding-top: 27.5px;

  .speech-bubble__point {
    position: absolute;
    top: 0;
    right: 0;
  }

  .speech-bubble__inner {
    border-top-right-radius: 0;
  }

  .speech-bubble__point {
    width: 25px;
    height: 28px;
    
    svg {
      display: block;
      height: 100%;
    }
  }
}