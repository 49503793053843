.page-lead {
  position: relative;
  height: 100vh;
  height: calc(100vh - 60px - var(--ios-gap, 0.01px));
}

.page-lead__title {
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 20;
  margin-top: 32px;

  @media (--md-min) {
    margin-top: 78px;
  }
}