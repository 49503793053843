.home-slideshow {
  height: 100vh;
  height: calc(100vh - 60px - var(--ios-gap, 0.01px));
  position: relative;

  .swiper-container {
    height: 100%;
  }
}

.home-slide {
  transition: color 350ms;

  &[data-theme="white"] {
    color: #fff;
  }
}

.home-slide__img {
  height: 100%;
  width: 100%;  
} 

.home-slide__title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--font-size-large-sm);
  z-index: 2;
  text-align: center;

  @media (width >= 350px) {
    font-size: 8vw;
  }

  @media (--xl) {
    font-size: 115px;
  }
}

.home-slideshow__pagination {
  position: absolute;
  bottom: var(--margin-section-top-lg);
  right: var(--margin-outer-lg);
  z-index: 2;
  transition: color 350ms;
  font-size: 14px;

  .pagination {
    margin: 0;
  }

  @media (--md-max) {
    display: none;
  }

  /* [data-theme="white"] & {
    color: #fff;
  } */

  .swiper-pagination-bullet {
    background-color: #e8f2f0;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    transition: all 350ms;

    @media (hover: hover) {
      &:hover {
        transform: scale(1.1);
      }
      
      &:not(.swiper-pagination-bullet-active):hover {
        background-color: #fff;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: #000;
    color: #fff;
  }
}

.home-slideshow__proceed {
  position: absolute;
  bottom: var(--margin-outer-sm);
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 350ms;

  &.is-hidden {
    opacity: 0;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;

      svg {
        transform: scale(1.1);
      }
    }
  }

  &:focus {
    outline: 0;
  }

  svg {
    display: block;
    width: 22px;
    height: 22px;
    transition: all 350ms;
  }

  @media (--md-max) {
    svg {
      stroke-width: 2px;
    }

    .label {
      margin-bottom: 5px;
    }
  }

  @media (--md-min) {
    bottom: var(--margin-section-top-lg);

    svg {
      width: 22px;
      height: 22px;
    }

    .label {
      margin-bottom: 15px;
    }
  }
}