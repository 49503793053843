.section {
  padding: var(--margin-section-top-sm) 0 0.1px;

  @media (--md-min) {
    padding: var(--margin-section-top-lg) 0 0.1px;
  }
}

.section-without-title {
  padding: 0.1px 0;
}

.section-title {
  text-align: center;
  max-width: 1440px;
  margin-right: auto;
  margin-left: auto;

  @media (--md-min) {
    padding-right: 60px;
    padding-left: 60px;
  }
}

.container {
  padding: 0 var(--margin-outer-sm);

  @media (--md-min) {
    padding: 0 var(--margin-outer-lg);
  }
}

.section-content {
  margin-top: var(--margin-large-sm);
  margin-bottom: var(--margin-large-sm);

  .section-without-title & {
    margin-top: var(--margin-unit-sm);
  }

  @media (--md-min) {
    margin-top: var(--margin-large-lg);
    margin-bottom: var(--margin-large-lg);
    
    .section-without-title & {
      margin-top: var(--margin-unit-sm);
    }
  }
}

.section-content--with-title {
  margin-top: var(--margin-unit-sm);

  @media (--md-min) {
    margin-top: var(--margin-unit-lg);
  } 
}