.grid-wrap {
  overflow: hidden;

  @media (--sm) {
    padding: 0 var(--margin-unit-sm);
  }

  @media (--md-min) {
    padding: 0 var(--margin-unit-lg);
  }

  .block & {
    padding: 0;
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media (--sm) {
    margin-right: calc(var(--margin-gutter-sm) * -0.5);
    margin-left: calc(var(--margin-gutter-sm) * -0.5);
  }

  @media (--md-min) {
    margin-right: calc(var(--margin-gutter-lg) * -0.5);
    margin-left: calc(var(--margin-gutter-lg) * -0.5);
  }
}

.grid-item {
  padding: 0 calc(var(--margin-gutter-sm) * 0.5);

  @media (--sm-max) {
    .block & {
      padding: 0;
    }
  }

  @media (--md-min) {
    padding: 0 calc(var(--margin-gutter-lg) * 0.5);
  }
}

.grid-item--small {
  width: 100%;

  @media (--sm-max) {
    &.grid-item--portrait {
      .grid-item__link {
        margin: 0 auto;
      }
    }
  }

  @media (--sm-min) {
    width: 50%;
    max-width: 570px;
  }

  @media (--md-min) {
    &.grid-item--portrait {
      width: auto;
    }
  }
}

.grid-item--event {
  width: 33.33%;
}

.grid-item__link {
  display: table;
}

.grid-item__link--block {
  display: block;

  .grid-item__caption {
    display: block;
  }

  .grid-item__img {
    width: 100%;
  }
}

.grid-item--large {
  width: 100%;
  
  .grid-item__link {
    width: 100%;
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
  }

  .robot {
    display: flex;
    justify-content: center;
  }
}

.grid-item__caption {
  margin-top: 10px;
  display: table-caption;
  caption-side: bottom;
}

.grid-item__img {
  position: relative;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.grid-item--small .grid-item__img {
  img {
    width: auto;
    max-width: 100%;
    max-height: 100vw;
    max-height: calc(100vw - (var(--margin-outer-sm) * 2));

    @media (--md-min) {
      max-height: 520px;
    }
  }
}

.grid-item__img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  font-size: 39px;
  text-align: center;
  padding: var(--margin-outer-sm);

  @media (width >= 866px) {
    font-size: 4.5vw;
  }

  @media (--xl) {
    font-size: 64px;
  }
}

.link-grid {
  margin-bottom: calc(var(--margin-unit-sm) * -1.5);

  @media (--md-min) {
    margin-bottom: calc(var(--margin-large-lg) * -1);
  }
}

.link-item {
  margin-bottom: calc(var(--margin-unit-sm) * 1.5);

  @media (--md-min) {
    margin-bottom: var(--margin-large-lg);
  }
}

.link-item--flex {
  align-self: stretch;
  display: flex;

  .grid-item__link {
    display: flex;
    flex-direction: column;
  }

  .image-grid__title {
    flex: 1 0 auto;
  }
}