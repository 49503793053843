.site-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 10px var(--margin-outer-sm);
  transition: color 350ms;

  html[data-theme="white"] body:not(.is-page-lead-scrolled) & {
    color: #fff;
  }

  @media (--md-max) {
    .is-nav-active & {
      color: #000 !important;
    }
  }

  @media (--md-min) {
    padding: var(--margin-section-top-lg) var(--margin-outer-lg) 0;
  }
}

.site-title__inner {
  position: relative;
}

.site-title__logo {
  font-size: var(--font-size-large-sm);
  white-space: nowrap;
  text-align: center;

  @media (--md-min) {
    font-size: var(--font-size-large-lg);

    .has-collapsed-header & {
      font-size: var(--font-size-medium-lg);
    }
  }
}
