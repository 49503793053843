.event-calendar-wrap {
  .events-calendar:first-child {
    margin-top: var(--margin-unit-sm);

    @media (--md-min) {
      margin-top: var(--margin-unit-lg);
    }
  }

  .events-calendar:last-child {
    margin-bottom: var(--margin-unit-sm);

    @media (--md-min) {
      margin-bottom: var(--margin-unit-lg);
    }
  }  
}

.event-title {
  margin-top: var(--line-height-medium-sm);
  margin-bottom: var(--margin-unit-sm);
  max-width: 30em;
  margin-right: auto;
  margin-left: auto;

  @media (--md-min) {
    margin-top: calc(var(--line-height-medium-lg) - 17px);
    margin-bottom: var(--margin-unit-lg);
  }
}

.event-content {
  margin-bottom: var(--margin-large-sm);

  @media (--md-min) {
    margin-bottom: var(--margin-large-lg);
  }
}