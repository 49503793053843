.logo-row {
  overflow: hidden;
}

.logo-row__item {
  padding: calc(var(--margin-outer-sm) * 0.5);

  @media (--sm) {
    padding: calc(var(--margin-gutter-sm) * 0.5);
  }

  @media (--md-min) {
    padding: 1rem;
  }

  img {
    width: auto;
    height: auto;
    max-width: 100px;
    max-height: 50px;

    @media (--md-min) {
      max-height: 65px;
      max-width: 130px;
    }
  }
}

.logo-row__wrap {
  margin: calc(var(--margin-outer-sm) * -0.5);

  @media (--sm) {
    margin: calc(var(--margin-gutter-sm) * -0.5);
  }

  @media (--md-min) {
    margin: -1rem;
  }
}

.logo-row__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}