.event-accordion__grid {
  margin: var(--margin-unit-sm) 0 var(--margin-unit-sm);

  @media (--md-min) {
    margin: var(--margin-unit-lg) 0 var(--margin-unit-lg);
  }
}

.event-accordion {
  overflow: hidden;
}

.event-accordion__body {
  .is-collapsed & {
    height: 0;
    overflow: hidden;
  }
}

.event-accordion__header {
  display: flex;
  justify-content: center;
}

.event-accordion__title {
  cursor: pointer;
  border-bottom: 3px solid;

  .is-collapsed & {
    border-bottom: 0;
  }
}