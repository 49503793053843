.site-nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 97;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--margin-outer-sm);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (--md-max) {
    text-align: center;
  }

  @media (--md-min) {
    max-width: 310px;
    z-index: 100;
    background-color: #fbfbfb;
    padding: var(--margin-section-top-lg) var(--margin-outer-lg);
  }

  html:not(.is-nav-active) & {
    display: none;
  }
}

.nav-close {
  span {
    display: block;
    padding-bottom: 4px;
    border-bottom: 2px solid transparent;
  }

  @media (--md-max) {
    display: none;
  }

  @media (hover: hover) {
    &:hover span {
      border-color: inherit;
    }
  }
}

.nav-menu {
  @media (--md-max) {
    margin: 60px 0;
    font-size: var(--font-size-large-sm);
  }

  @media (--md-min) {
    margin: var(--margin-outer-lg) 0;
  }
}

.nav-menu__item {
  &:not(:last-child) {
    margin-bottom: 0.5em;
  }

  a {
    display: inline-block;
    border-bottom: 2px solid transparent;
  }

  &.is-active {
    & > a {
      border-bottom-color: inherit;
    }
  }

  @media (hover: hover) {
    a:hover {
      border-bottom-color: inherit;
    }
  }
}

.site-nav__social {
  @media (--md-max) {
    margin-top: 25px;
    margin-bottom: var(--margin-outer-sm);
  }

  @media (--md-min) {
    margin-top: 0.666em;

    .social-media {
      justify-content: flex-start;
    }
  }
}

.site-nav__contact {
  a {
    border-bottom: 2px solid transparent;

    @media (hover: hover) {
      &:hover {
        border-color: inherit;
      }
    }
  }
}

.sub-menu {
  padding: 0.5em 0 1.25em;
  display: none;

  @media (--md-min) {
    padding-left: 1em;
  }

  .is-sub-nav-active & {
    display: block;
  }
}