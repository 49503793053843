.homepage-robot-title {
  margin-top: calc(var(--margin-unit-sm) * -0.5);

  @media (--md-min) {
    margin-top: calc(var(--margin-unit-lg) * -0.5);
  }

  .robot-with-wrapper {
    margin: calc(var(--margin-unit-sm) * 1.5) 0;

    @media (--md-min) {
      margin: calc(var(--margin-unit-lg) * 1.5) 0;
    }
  }
}