.block {
  margin-top: var(--margin-unit-sm);
  margin-bottom: var(--margin-unit-sm);
  padding-right: var(--margin-outer-sm);
  padding-left: var(--margin-outer-sm);

  @media (--sm) {
    margin-top: var(--margin-unit-lg);
    margin-bottom: var(--margin-unit-lg);
    padding-right: var(--margin-gutter-lg);
    padding-left: var(--margin-gutter-lg);
  }

  @media (--md-min) {
    margin-top: var(--margin-unit-lg);
    margin-bottom: var(--margin-unit-lg);
    padding-right: var(--margin-unit-lg);
    padding-left: var(--margin-unit-lg);
  }
}