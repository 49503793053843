.robot-with-wrapper {
  margin: var(--margin-unit-sm) 0;
  padding: 0 var(--margin-outer-sm);

  @media (--sm) {
    padding: 0 var(--margin-unit-sm);
  }

  @media (--md-min) {
    margin: var(--margin-unit-lg) 0;
    padding: 0 var(--margin-unit-lg);
  }
}

.robot__wrap {
  display: flex;
  align-items: flex-start;
}

.robot[data-animate] {
  .speech-bubble {
    /* opacity: 0; */
    clip-path: inset(0 0 100% 0);
    transition: clip-path 500ms 750ms var(--ease); 

    @media (--md-min) {
      transition-duration: 750ms;
      transition-delay: 900ms;
    }
  }

  &.is-animated {
    .speech-bubble {
      clip-path: inset(0 0 0 0);
    }

    &.robot-side-left.robot-size-large,
    &.robot-side-right.robot-size-large {
      .speech-bubble {
        @media (--md-max) {
          transform: translate3d(0, 0, 0);
        }
      }
    }

    .robot__img {
      transform: translate3d(0, 0, 0);
    }
  }
}

.robot__img {
  display: block;
  max-width: 80px;
  max-height: 80px;
  width: auto;
  height: auto;
  transition: transform 1000ms var(--ease-out), -webkit-transform 1000ms var(--ease-out);

  @media (--md-min) {
    max-width: 150px;
    max-height: 150px;
    transition: transform 1500ms var(--ease-out), -webkit-transform 1500ms var(--ease-out);
  }
}

.robot-size-small {
  .speech-bubble {
    max-width: 215px;
  }
}

.robot-size-medium {
  .speech-bubble {
    max-width: 372px;
  }
}

.robot-size-large {
  .speech-bubble {
    max-width: 678px;
  }
}

.robot-side-left {
  .robot__img {
    margin-right: 10px;

    @media (--md-min) {
      margin-right: 30px;
    }
  }

  .speech-bubble {
    margin-top: 25px;
  }

  @media (--md-max) {
    &.robot-size-large {
      .robot__wrap {
        flex-direction: column;
      }

      .robot__img {
        margin-bottom: 10px;
      }

      .speech-bubble {
        margin-top: 0;
        padding-left: 30px;
        padding-top: 27.8px;
      }

      .speech-bubble__point {
        top: 0;
        left: 30px;
        transform: rotate(180deg);
      }
    }
  }
}

.robot-side-right {
  .robot__wrap {
    flex-direction: row-reverse;
  }

  .speech-bubble {
    margin-top: 25px;
  }

  .robot__img {
    margin-left: 10px;

    @media (--md-min) {
      margin-left: 30px;
    }
  }

  @media (--md-max) {

    &.robot-size-large {
      .robot__wrap,
      .speech-bubble {
        flex-direction: column;
        align-items: flex-end;
      }

      .robot__img {
        margin-bottom: 10px;
      }

      .speech-bubble {
        margin-top: 0;
        padding-right: 30px;
        padding-top: 27.8px;
      }

      .speech-bubble__point {
        right: 30px;
        transform: rotate(180deg);
      }
    }
  }
}

.robot-side-top {
  .robot__wrap {
    flex-direction: column;
    align-items: center;
  }

  .robot__img {
    margin-bottom: 10px;

    @media (--md-min) {
      margin-bottom: 30px;
    }
  }
}

.robot-side-left[data-animate] {
  .robot__img {
    transform: translate3d(-200%, 100%, 0) rotate(20deg);
  }

  .speech-bubble {
    transform: translate3d(-30px, 0, 0) scale(0.95);
  }

  @media (--md-max) {
    .speech-bubble {
      transform: translate3d(-15px, 0, 0) scale(0.95);
    }

    &.robot-size-large {
      .speech-bubble {
        transform: translate3d(0, -15px, 0) scale(0.95);
      }
    }
  }
}

.robot-side-right[data-animate] {
  .speech-bubble {
    transform: translate3d(30px, 0, 0) scale(0.95);
  }

  .robot__img {
    transform: translate3d(150%, 100%, 0) rotate(-90deg);
  }

  @media (--md-max) {
    .speech-bubble {
      transform: translate3d(15px, 0, 0) scale(0.95);
    }

    &.robot-size-large {
      .speech-bubble {
        transform: translate3d(0, -15px, 0) scale(0.95);
      }
    }
  }
}

.robot-side-top[data-animate] {
  .speech-bubble {
    transform: translate3d(0, -30px, 0) scale(0.95);

    @media (--md-max) {
      transform: translate3d(0, -15px, 0) scale(0.95);
    }
  }

  .robot__img {
    transform: translate3d(20%, 100%, 0) rotate(-20deg);
  }
}