.pagination {
  display: flex;
  justify-content: center;
  margin: -0.5em;
}

.swiper-pagination-bullet {
  width: auto;
  height: auto;
  color: inherit;
  background: none;
  opacity: 1;
  padding: 0.5em;
  user-select: none;
}

.swiper-pagination-bullet-active {
  color: #b2b2b1;
}