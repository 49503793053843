.homepage-back-to-top {
  margin-bottom: var(--margin-unit-sm);

  @media (--md-min) {
    margin-bottom: var(--margin-large-sm);
  }

  .robot {
    margin: 0;
  }
}