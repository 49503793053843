@font-face {
  font-family: "Unica77";
  src: url("../fonts/Unica77LLSub-MediumSubset.woff2") format("woff2"),
      url("../fonts/Unica77LLSub-MediumSubset.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: "Unica77";
  src: url("../fonts/Unica77LLSub-MediumItalicSubset.woff2") format("woff2"),
  url("../fonts/Unica77LLSub-MediumItalicSubset.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

body {
  font-family: var(--font-stack);
  font-size: var(--font-size-regular-sm);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (--md-min) {
    font-size: var(--font-size-regular-lg);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  margin: 0;
}

.t-baseline {
  padding: 0.05px 0;

  &::before { 
    content: "";  
    margin-top: -0.2em;  
    display: block; 
    height: 0;  
    width: 100%;
  }

  &::after {  
    content: "";  
    margin-bottom: -0.2em; 
    display: block; 
    height: 0;  
    width: 100%;
  }
}

.t-size-small {
  font-size: var(--font-size-small-sm);
  line-height: var(--line-height-small-sm);

  &.t-paragraph {
    p + p {
      margin-top: var(--line-height-small-sm); 
    }
  }

  @media (--md-min) {
    font-size: var(--font-size-small-lg);
    line-height: var(--line-height-small-lg);

    &.t-paragraph {
      p + p {
        margin-top: var(--line-height-small-lg); 
      }
    }
  }
}

.t-size-regular {
  font-size: var(--font-size-regular-sm);
  line-height: var(--line-height-regular-sm);

  &.t-paragraph {
    p + p {
      margin-top: var(--line-height-regular-sm); 
    }
  }

  @media (--md-min) {
    font-size: var(--font-size-regular-lg);
    line-height: var(--line-height-regular-lg);

    &.t-paragraph {
      p + p {
        margin-top: var(--line-height-regular-lg); 
      }
    }
  }
}

.t-size-medium {
  font-size: var(--font-size-medium-sm);
  line-height: var(--line-height-medium-sm);

  @media (--md-min) {
    font-size: var(--font-size-medium-lg);
    line-height: var(--line-height-medium-lg);
  }
}

.t-size-large {
  font-size: var(--font-size-large-sm);

  @media (--md-min) {
    font-size: var(--font-size-large-lg);
  }
}

.t-align-c {
  text-align: center;
}

.t-color-white {
  color: #fff;
}

.t-color-black {
  color: #000;
}

.text-content {
  width: 100%;
  max-width: 39em;
  margin-right: auto;
  margin-left: auto;

  a {
    display: inline-block;
    border-bottom: 1px solid;

    @media (--md-min) {
      border-width: 2px;
    }
    
    &:hover {
      border-color: transparent;
    }
  }
}