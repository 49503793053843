.event-grid {
  margin-bottom: calc(var(--margin-unit-sm) * -1.5);
  align-items: stretch;

  &.event-grid--single {
    justify-content: center;
  }

  @media (--md-min) {
    margin-bottom: calc(var(--margin-unit-lg) * -1);
  }
}

.event-grid-empty {
  margin-bottom: var(--margin-unit-sm);

  @media (--md-min) {
    margin-bottom: var(--margin-unit-lg);
  }
}

.event-thumb {
  display: flex;
  margin-bottom: calc(var(--margin-unit-sm) * 1.5);

  @media (--md-min) {
    margin-bottom: var(--margin-unit-lg);
  }
}

.event-thumb__link {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.event-thumb__header {
  flex: 1 0 auto;
}

.event-thumb__title,
.event-thumb__date {
  text-align: center;
}

.event-thumb__date {
  margin-top: 10px;
}

.event-thumb__img {
  position: relative;
  margin-top: 15px;
  width: 100%;
  height: 0;
  padding-bottom: 66.66%;
  background-color: #fff;
  z-index: 2;

  .swiper-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (--md-min) {
    margin-top: 30px;
  }
}

@media (hover: hover) {
  .event-thumb {
    &:hover {
      .event-thumb__description {
        transform: translateY(0);
      }
    }
  }

  .event-thumb__description {
    transform: translateY(-100%);
    transition: transform 500ms var(--ease), -webkit-transform 500ms var(--ease);
  }
}

.event-thumb__description p {
  @media (--md-min) {
    height: calc(var(--line-height-small-lg) * 4);
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.event-thumb__description,
.event-thumb__details,
.event-thumb__more {
  padding-top: var(--line-height-small-sm);

  @media (--md-min) {
    padding-top: var(--line-height-small-lg);
  }
}

.event-thumb__more {
  padding-bottom: 1px;

  span {
    border-bottom: 1px solid;
  }

  @media (--md-min) {
    padding-bottom: 3px;

    span {
      border-bottom: 2px solid;
    }
  }
}