.instagram-feed {
  position: relative;
  margin-top: var(--margin-unit-sm);

  @media (--sm-max) {
    .swiper-container {
      width: calc(100% - 50px);
      max-width: 320px;
    }
  }

  .swiper-container {
    margin: calc(var(--margin-section-top-sm) * -1) auto;
    padding: var(--margin-section-top-sm) 0;

    @media (--md-min) {
      margin: calc(var(--margin-gutter-lg) * -1) auto;
      padding: var(--margin-gutter-lg) 0;
    }
  }

  .swiper-wrapper {
    justify-content: stretch;
    align-items: stretch;
  }

  @media (--sm) {
    padding: 0 var(--margin-unit-sm);
  }

  @media (--md-min) {
    margin-top: var(--margin-unit-lg);
    padding: 0 var(--margin-unit-lg);
  }
}

.instagram-feed__inner {
  width: 100%;
  max-width: 1020px;
  margin-right: auto;
  margin-left: auto;
}

.instagram-feed__title {
  margin-bottom: var(--margin-section-top-sm);
  display: flex;
  align-items: center;
  justify-content: center;

  h2 {
    order: 1;
    margin: 0 var(--margin-section-top-sm);
  }

  @media (--md-min) {
    margin-bottom: var(--margin-gutter-lg);

    h2 {
      margin: 0 var(--margin-section-top-lg);
    }
  }
}

.instagram-feed__btn--prev {
  order: 0;
}

.instagram-feed__btn--next {
  order: 2;
}

.single-instagram {
  color: #fff;
  flex: 0 0 auto;
  width: 33.333333%;
  flex: 1 0 auto;
  height: auto;
  display: flex;
  transition: transform 350ms, -webkit-transform 350ms;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.06);
    }
  }

  @media (--sm) {
    width: 50%;
  }

  @media (--sm-max) {
    padding: 0 calc(var(--margin-outer-sm) * 0.5);
  }

  @media (--sm-min) {
    padding: 0 0.5rem;
  }

  @media (--md-min) {
    width: 33.333333%;
  }
}

.single-instagram__link {
  background-color: #000;
  display: block;
  display: flex;
  flex-direction: column;
}

.single-instagram__img {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;

  img {
    width: 102%;
    height: 102%;
    margin: -1% 0 0 -1%;
    backface-visibility: hidden;
  }
}

.single-instagram__body {
  padding: 1rem;
  flex: 1 0 auto;
}

.instagram-feed__btn {
  transition: opacity 350ms;

  &.swiper-button-disabled {
    opacity: 0;
  }

  &:focus {
    outline: 0;
  }

  @media (hover: hover) {
    &:hover {
      svg {
        transform: scale(1.1);
      }
    }
  }

  svg {
    display: block;
    width: 22px;
    height: 22px;
    transition: all 350ms;
  }

  @media (--sm-max) {
    padding: 10px;
    margin: -10px;

    svg {
      margin: 0 auto;
    }
  }

  @media (--sm-min) {
    padding: 10px;
    margin: -10px;

    svg {
      width: 22px;
      height: 22px;
    }
  }
}

.instagram-feed__btn--prev {
  left: 0;

  @media (--sm) {
    left: var(--margin-outer-sm);
  }

  @media (--md-min) {
    left: var(--margin-outer-lg);
  }
}

.instagram-feed__btn--next {
  right: 0;

  @media (--sm) {
    right: var(--margin-outer-sm);
  }

  @media (--md-min) {
    right: var(--margin-outer-lg);
  }
}