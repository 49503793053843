.homepage-intro {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (--md-max) {
    padding: 0 var(--margin-outer-sm);
  }

  @media (--md-min) {
    max-width: 950px;
  }
}

.homepage-intro-bubble__img {
  opacity: 0;
  transition: transform 1500ms var(--ease-out), -webkit-transform 1500ms var(--ease-out), opacity 500ms;

  @media (--md-min) {
    transition: transform 2500ms var(--ease-out), -webkit-transform 2500ms var(--ease-out), opacity 500ms;
  }
}

.homepage-intro-bubble .speech-bubble {
  clip-path: inset(0 0 100% 0);
  transition: clip-path 500ms 750ms var(--ease); 

  @media (--md-max) {
    transition-duration: 1000ms;
  }
}

.homepage-intro.is-animated {
  .homepage-intro-bubble__img {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  .speech-bubble {
    clip-path: inset(0 0 0 0);
  }
}

.homepage-intro-bubble--first {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--margin-unit-sm);

  .homepage-intro-bubble__img {
    display: block;
    width: 98px;
    height: auto;
    margin-bottom: 10px;
    transform: translate3d(20%, 100%, 0) rotate(-20deg);
    transition-delay: 100ms;
  }

  .speech-bubble {
    width: 100%;
    transition-delay: 1500ms;
  }

  @media (--md-max) {
    .speech-bubble {
      transition-delay: 1500ms;
    }
  }

  @media (--md-min) {
    margin-bottom: var(--margin-unit-lg);

    .homepage-intro-bubble__img {
      width: 208px;
      margin-bottom: 50px;
    }

    .speech-bubble {
      max-width: 575px;
    }
  }
}

.homepage-intro-bubble--second {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--margin-unit-sm);

  .homepage-intro-bubble__img {
    display: block;
    height: auto;
    transform: translate3d(-200%, 100%, 0) rotate(20deg);
    transition-delay: 500ms;
  }

  .speech-bubble {
    width: 100%;
    transition-delay: 2350ms;
  }

  @media (--md-max) {
    flex-direction: column;
    margin-left: 18px;

    .speech-bubble {
      max-width: calc(100% - 16px - (18px * 2));
      transition-delay: 3000ms;
      margin-left: 16px;
      padding-left: 0;
      padding-top: 27.8px;
    }

    .speech-bubble__point {
      transform: rotate(-180deg);
    }

    .homepage-intro-bubble__img {
      width: 32px;
      margin-bottom: 10px;
      transition-delay: 2000ms;
    }
  }

  @media (--md-min) {
    margin-bottom: var(--margin-unit-lg);

    .homepage-intro-bubble__img {
      width: 68px;
      margin-top: -150px;
      margin-right: 30px;
    }

    .speech-bubble {
      max-width: 420px;
    }
  }
}

.homepage-intro-bubble--third {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .homepage-intro-bubble__img {
    display: block;
    height: auto;
    width: 105px;
    margin-bottom: 10px;
    transform: translate3d(150%, 100%, 0) rotate(-90deg);
    transition-delay: 900ms;
  }

  .speech-bubble {
    width: 100%;
    transition-delay: 3150ms;
  }

  @media (--md-max) {
    .speech-bubble {
      max-width: calc(100% - 28px - (18px * 2));
      margin-right: calc(16px + 28px);
      transition-delay: 4400ms;
    }

    .homepage-intro-bubble__img {
      margin-bottom: 10px;
      transition-delay: 3000ms;
    }
  }

  @media (--md-min) {
    .homepage-intro-bubble__img {
      width: 214px;
      margin-bottom: 30px;
      margin-top: -260px;
    }

    .speech-bubble {
      margin-right: 50px;
      max-width: 660px;
    }
  }
}