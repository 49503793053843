:root {
  --ios-gap: 0.01px;
  --font-stack: "Unica77", "Helvetica", "Arial", sans-serif;
}

@custom-media --xxs (width < #{$size-xs});
@custom-media --xs (width >= #{$size-xs}) and (width < #{$size-sm});
@custom-media --sm (width >= #{$size-sm}) and (width < #{$size-md});
@custom-media --md (width >= #{$size-md}) and (width < #{$size-lg});
@custom-media --lg (width >= #{$size-lg}) and (width < #{$size-xl});
@custom-media --xl (width >=#{$size-xl});

@custom-media --sm-min (width >= #{$size-sm});
@custom-media --sm-max (width < #{$size-sm});

@custom-media --md-min (width >= #{$size-md});
@custom-media --md-max (width < #{$size-md});

:root {
  --margin-outer-lg: 32px;
  --margin-gutter-lg: 50px;
  --margin-unit-lg: 100px;
  --margin-large-lg: calc(var(--margin-unit-lg) * 2);
  --margin-section-top-lg: 25px;

  --margin-outer-sm: 12px;
  --margin-gutter-sm: 25px;
  --margin-unit-sm: 50px;
  --margin-large-sm: calc(var(--margin-unit-sm) * 2);
  --margin-section-top-sm: 20px;

  --font-size-small-lg: 14px;
  --font-size-regular-lg: 18px;
  --font-size-medium-lg: 34px;
  --font-size-large-lg: 53px;

  --line-height-small-lg: 18px;
  --line-height-regular-lg: 24px;
  --line-height-medium-lg: 40px;

  --font-size-small-sm: 14px;
  --font-size-regular-sm: 16px;
  --font-size-medium-sm: 28px;
  --font-size-large-sm: 28px;

  --line-height-small-sm: 18px;
  --line-height-regular-sm: 21px;
  --line-height-medium-sm: 32px;

  --color-grey: #b2b2b1;

  --limit-width: 1240px;

  --ease: cubic-bezier(.4,0,.2,1);
  --ease-out: cubic-bezier(0,0,.2,1);
}