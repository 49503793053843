.image-row {
  overflow: hidden;
}

.image-row__item {
  width: 100%;
  padding: calc(var(--margin-outer-sm) * 0.5);

  @media (--sm-min) {
    width: 33.33%;
  }

  @media (--sm) {
    padding: calc(var(--margin-gutter-sm) * 0.5);
  }

  @media (--md-min) {
    padding: 1rem;
  }
}

.image-row__wrap {
  margin: calc(var(--margin-outer-sm) * -0.5);

  @media (--sm) {
    margin: calc(var(--margin-gutter-sm) * -0.5);
  }

  @media (--md-min) {
    margin: -1rem;
  }
}

.image-row__inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.image-row__caption {
  margin-top: 10px;
}