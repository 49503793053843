.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 0 var(--margin-outer-sm);
  transition: color 350ms 0ms, background-color 350ms 0ms;
  pointer-events: none;

  .has-collapsed-header & {
    position: sticky;
  }

  html:not(.is-nav-active) .is-page-lead-scrolled[data-scroll-dir="up"] & {
    background-color: #fff;
  }

  html[data-theme="white"] body:not(.is-page-lead-scrolled) & {
    color: #fff;
  }

  @media (--md-max) {
    html.is-nav-active & {
      background-color: #fff !important;
    }
  }

  @media (--md-max) {
    .is-nav-active & {
      color: #000 !important;
    }

    height: 40px;
  }

  @media (--md-min) {
    padding: var(--margin-section-top-lg) var(--margin-outer-lg);
    height: 73px;
  }
}

.site-header__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: var(--font-size-large-sm);
  white-space: nowrap;
  opacity: 0;
  transition: opacity 350ms 0ms;

  @media (--md-min) {
    top: 10px;
    transform: translateX(-50%);
      font-size: var(--font-size-medium-lg);
  }

  html:not(.is-nav-active) .is-page-lead-scrolled[data-scroll-dir="up"] & {
    opacity: 1;
    pointer-events: all;
  }

  @media (--md-max) {
    html.is-nav-active & {
      opacity: 1 !important;
      pointer-events: all !important;
    }
  }
}

.site-header__inner {
  position: relative;

  @media (--md-max) {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.nav-icon {
  display: block;
  pointer-events: all;

  @media (hover: hover) {
    &:hover {
      .nav-icon__desktop {
        border-color: inherit;
      }
    }
  }

  &:focus {
    outline: none;

    .nav-icon__desktop {
      border-color: inherit;
    }
  }

  @media (--md-min) {
    padding: 10px;
    margin: -10px -10px -14px;
  }
}

.nav-icon__desktop {
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;

  @media (--md-max) {
    display: none;
  }
}

.nav-icon__mobile {
  position: relative;
  height: 40px;
  padding: 13px 10px;
  margin-left: -10px;

  .wrapper {
    position: relative;
    height: 14px;
    width: 28px;
  }

  html[data-theme="white"] body:not(.is-page-lead-scrolled) & {
    span {
      background-color: #fff;
    }
  }

  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 27px;
    height: 2px;
    background-color: #000;
    transition: all 350ms;
    transform-origin: 50% 50%;

    .is-nav-active & {
      background-color: #000 !important;
    }

    &:first-child {
      transform: translate(-50%, -50%) translateY(-6px);
    }

    &:last-child {
      transform: translate(-50%, -50%) translateY(6px);
    }

    .is-nav-active & {
      &:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  @media (--md-min) {
    display: none;
  }
}