.page-image {
  width: 100%;
  max-width: 926px;
  margin-right: auto;
  margin-left: auto;
}

.page-image__caption {
  text-align: center;
  margin-top: 10px;
}