.page-slideshow {
  width: 100%;
  max-width: 926px;
  margin-right: auto;
  margin-left: auto;
}

.page-slideshow__img {
  width: 100%;
  height: 0;
  padding-bottom: 75%;
  overflow: hidden;
}

.page-slideshow__caption,
.page-slideshow__pagination {
  text-align: center;
}

.page-slideshow__pagination {
  overflow: hidden;
  margin-top: 10px;
}

.page-slideshow__caption {
  margin-top: 5px;
}